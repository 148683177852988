<template>
  <v-container>
    <v-row>

      <!-- COUNTER OPERATION -->
      <v-col cols="12" sm="6" md="4">
        <v-card outlined>
          <v-card-title>
          </v-card-title>
          <v-card-text>
            <v-text-field filled rounded label="櫃位／條碼名稱"
              :error-messages="validation.firstError('form.name')"
              v-model="form.name">
            </v-text-field>
            <v-text-field filled rounded label="付款完成通知URL（選填）"
              :error-messages="validation.firstError('form.notifyUrl')"
              v-model="form.notifyUrl">
            </v-text-field>

            <v-btn x-large rounded color="primary"
              :loading="form.isProcessing" :disabled="form.isProcessing"
              @click="createCounter">
              <v-icon left>mdi-plus-box-outline</v-icon>
              新增櫃位與條碼
            </v-btn>
            <br>
            <v-btn x-large rounded color="success"
              @click="showInviteDialog">
              <v-icon left>mdi-account-multiple-plus</v-icon>
              產生商家管理員／店員邀請URL
            </v-btn>
          </v-card-text>
        </v-card>
        
      </v-col>


      <!-- LIST OF COUNTERS -->
      <v-col cols="12" sm="6" md="4" v-for="counter in counters" :key="counter.paymentCodeId">
        <v-card outlined>
          <v-card-title>
            {{ counter.name }}
          </v-card-title>
          <v-card-text>
            <span class="d-block text-h4 text-center">
              代碼：{{ counter.code }}
              <v-btn icon small @click="copyText(counter.code)">
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </span>
            <!-- <v-img class="mx-auto" width="300px"
              :src="'https://chart.apis.google.com/chart?cht=qr&choe=UTF-8&chs=300x300&chl=' + (counter.shortUrl || counter.url)"
              >
            </v-img> -->
            <v-img class="mx-auto" width="240px"
              :src="counter.qrcode"
              ></v-img>
          </v-card-text>
          <v-card-actions>
            <div class="flex-column">

              <div class="d-flex flex-wrap bg-surface-variant">
                <v-btn class="flex-1-0 ma-2 pa-2" @click="copyText(counter.shortUrl || counter.url)">
                  <v-icon left>mdi-content-copy</v-icon>
                  複製支付URL
                </v-btn>
                <v-btn class="ma-2 pa-2" color="red darken-3" @click="deleteCounter(counter)">
                  <v-icon left>mdi-delete</v-icon>
                  刪除櫃位/條碼
                </v-btn>
                <v-btn class="flex-1-1-100 ma-2 pa-2" color="warning" @click="showPrinterDialog(counter.shopId, counter.code)">
                  <v-icon left>mdi-printer</v-icon>
                  出單機管理
                </v-btn>
              </div>

              <v-switch dense hide-details label="啟用"
                :input-value="counter.status == 'active'"
                @change="togglePaymentCode($event, counter)">
              </v-switch>

              <v-switch dense hide-details label="隱藏線上支付選項（僅供使用會員點券）"
                :input-value="counter.settings? counter.settings.disableOnlinePayment === true: false"
                @change="toggleOnlinePayment($event, counter)">
              </v-switch>

            </div>

          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" v-if="!counters.length">
        目前沒有支付條碼
      </v-col>
    </v-row>
    

    <!-- Dialog - Invitation generator -->
    <v-dialog v-model="dialog.invite.show" width="50%">
      <v-card>
        <v-card-title>邀請成為商家管理員／店員</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-select :items="dialog.invite.roleOptions" v-model="dialog.invite.role"
                  :error-messages="validation.firstError('dialog.invite.role')">
                </v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-btn block color="parmary" @click="generateInviteUrl">
                  產生邀請連結
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="dialog.invite.url"
                  dense filled rounded append-icon="mdi-content-copy"
                  required hide-details
                  @click:append="copyText(dialog.invite.url)">
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog - printer -->
    <v-dialog v-model="dialog.printer.show" width="50%">
      <v-card>
        <v-card-title>出單機管理 - {{ dialog.printer.terminalId }}</v-card-title>
        <br/><br/>
        <v-card-subtitle>一個櫃位僅能關聯一台出單機，若關聯錯誤的出單機，請先解除關聯後再重新設定</v-card-subtitle>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <!-- <v-text-field filled rounded label="出單機序號"
                  placeholder="請輸入9碼序號"
                  :error-messages="validation.firstError('dialog.printer.printerSn')"
                  :disabled="dialog.printer.isPrinterBound"
                  v-model="dialog.printer.printerSn">
                </v-text-field> -->
                <v-text-field filled rounded label="出單機Device ID"
                  placeholder="請輸入出單機Device ID"
                  :error-messages="validation.firstError('dialog.printer.printerId')"
                  :disabled="dialog.printer.isPrinterBound"
                  v-model="dialog.printer.printerId">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-btn x-large rounded color="primary"
                  :loading="dialog.printer.isProcessing && !dialog.printer.isPrinterBound" 
                  :disabled="dialog.printer.isProcessing || dialog.printer.isPrinterBound"
                  @click="bindTerminalPrinter">
                  <v-icon left>mdi-plus</v-icon>
                  關聯出單機
                </v-btn>
                <v-btn x-large rounded color="success"
                  :loading="dialog.printer.isProcessing && dialog.printer.isPrinterBound" 
                  :disabled="dialog.printer.isProcessing || !dialog.printer.isPrinterBound"
                  @click="removeTerminalPrinter">
                  <v-icon left>mdi-delete</v-icon>
                  解除關聯出單機
                </v-btn>
                <v-btn x-large rounded color="warning"
                  :loading="dialog.printer.isPrinting" 
                  :disabled="dialog.printer.isProcessing || !dialog.printer.isPrinterBound || dialog.printer.isPrinting"
                  @click="printerTest">
                  <v-icon left>mdi-printer</v-icon>
                  出單測試
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

import SimpleVueValidation from 'simple-vue-validator';
import axios from 'axios';
import QRCode from 'qrcode';

const Validator = SimpleVueValidation.Validator;

export default {
  name: 'CounterSettings',
  props: {
    merchantId: String,
    merchantName: String
  },

  mounted() {
    // this.fetchCounters();
  },

  methods: {

    fetchCounters() {
      axios
      .get(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/ddpay/shops/${this.merchantId}/payment-codes`)
      .then(response => {
        // this.counters = response.data.counters;
        this.counters = response.data;
        this.generateQRCodes();
      })
      .catch(err => {
        this.$notify({
          type: 'error',
          title: `Cannot fetch counters for this merchant for now.`,
        });
      });
    },

    createCounter() {
      this.$validate([
        'form.name',
        'form.notifyUrl'
      ])
      .then(valid => {
        if (!valid) return;
        this.form.isProcessing = true;

        let settings = { };
        if (this.form.disableOnlinePayment)
          settings.disableOnlinePayment = true;

        let reqBody = {
          name: this.form.name,
          notifyUrl: this.form.notifyUrl
        };

        // If settings is not empty, merge it into reqBody
        if (Object.keys(settings).length)
          reqBody.settings = settings;
        
        return axios
        .post(
          `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/ddpay/shops/${this.merchantId}/payment-codes`,
          reqBody
        );
      })
      .then(response => {
        this.$notify({
          type: 'success', title: `已建立新的櫃位與條碼！如欲開放支付請記得啟用。`
        });
        this.form = {
          name: '',
          notifyUrl: ''
        };
        // Force counters refresh
        this.fetchCounters();
        this.form.isProcessing = false;
      })
      .catch(err => {
        console.error(`[COUNTER-SETTINGS-VIEW] createCounter: error`, err);
        this.$notify({
          type: 'error', title: `無法建立新的櫃位與條碼，請稍後再試。`
        });
        this.form.isProcessing = false;
      });
    },

    updateCounter() {

    },

    async toggleOnlinePayment(event, counter) {
      console.log(`toggleOnlinePayment`, event, counter);
      if (!counter.settings)
        counter.settings = { };
      counter.settings.disableOnlinePayment = event;

      try {
        let response = await axios({
          method: 'put',
          url: `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/ddpay/shops/${this.merchantId}/payment-codes/${counter.paymentCodeId}`,
          data: {
            settings: counter.settings
          }
        });

        this.$notify({
          type: 'success',
          text: '設置支付模式完成！'
        });

      } catch (err) {
        console.error(`[COUNTER-SETTINGS-VIEW] toggleOnlinePayment: error`, err);
        this.$notify({
          type: 'error', title: `無法更新支付模式設置，請稍後再試。`
        });
        counter.settings.disableOnlinePayment = !event;//Revert to the original position
      }
    },

    togglePaymentCode(event, counter) {
      console.log(`togglePaymentCode`, event);
      axios.put(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/ddpay/shops/${this.merchantId}/payment-codes/${counter.paymentCodeId}/enable`,
        { enable: event }
      )
      .then(response => {
        this.$notify({
          type: 'success',
          text: '此櫃檯／條碼已' + (event? '啟用': '停用')
        });
      })
      .catch(err => {
        this.$notify({
          type: 'error', text: '發生錯誤，無法設定此櫃檯／條碼的啟用狀態。'
        });
        counter.status = event? 'inactive': 'active';//Revert to the original position
      });
    },

    deleteCounter(counter) {
      this.$swal({
        text: 'Sure want to delete this payment-code/counter?',
        icon: 'warning',
        confirmButtonText: 'Yes!',
        showCloseButton: true
      })
      .then(result => {
        if (!result.isConfirmed) return;
        axios.delete(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/ddpay/shops/${this.merchantId}/payment-codes/${counter.paymentCodeId}`)
        .then(response => {
          this.fetchCounters();
          this.$notify({
            type: 'success', text: '已經刪除此櫃檯／條碼。'
          });
          // this.dialog.detail.show = false;
        })
        .catch(err => {
          this.$notify({
            type: 'error', text: '發生錯誤，無法刪除此櫃檯／條碼。'
          });
        })
        .finally(() => {
          // this.dialog.detail.isProcessing = false;
        });
      });
    },

    // Invitation logic
    showInviteDialog() {
      this.dialog.invite.url = '';
      this.dialog.invite.show = true;
    },
    generateInviteUrl() {
      this.$validate([
        'dialog.invite.role'
      ])
      .then(valid => {
        if (!valid) return;
        return axios.post(
          `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/qmp/invitation`,
          { merchantId: this.merchantId, role: this.dialog.invite.role }
        );
      })
      .then(response => {
        if (response && response.data)
          this.dialog.invite.url = response.data['invitationUrl'];
      })
      .catch(err => {
        this.$notify({
          type: 'error', text: 'Cannot generate invitation link!'
        });
      });
    },

    async generateQRCodes() {
      for (let i = 0; i < this.counters.length; i++) {
        try {
          const qrCodeDataURL = await QRCode.toDataURL(this.counters[i].shortUrl || this.counters[i].url, { errorCorrectionLevel: 'H' });
          // return qrCodeDataURL;
          console.log(`[COUNTER-SETTINGS-VIEW] generateQRCodes: qrCodeDataURL`, qrCodeDataURL);
          this.$set(this.counters[i], 'qrcode', qrCodeDataURL);
        } catch (err) {
          console.error(`[COUNTER-SETTINGS-VIEW] generateQRCodes: error`, err);
        }
      }
    },

    copyText(text) {
      if (!text.length)
        return;
      console.log(`[COUNTER-SETTINGS-VIEW]<DEBUG> copyText: trying to copy`, text);
      navigator.clipboard.writeText(text)
      .then(() => {
        //TODO: Alert about the successful copy.
        this.$notify({
          type: 'success',
          text: '已複製到剪貼簿！'
        });
      })
      .catch(err => {
        //TODO: Alert about the failed copy.
      });
    },

    showPrinterDialog(shopId, terminalId) {
      this.dialog.printer.shopId = shopId;
      this.dialog.printer.terminalId = terminalId;
      // this.dialog.printer.printerSn = '';
      this.dialog.printer.printerId = '';
      this.dialog.printer.show = true;
      this.validation.reset();
      this.getTerminalPrinter();

    },
    getTerminalPrinter() {
      this.dialog.printer.isProcessing = true;
      const shopId = this.dialog.printer.shopId;
      const terminalId = this.dialog.printer.terminalId;
      this.dialog.printer.isPrinterBound = false;
      axios.get(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/ddpay/shops/${shopId}/terminals/${terminalId}/printer`,
      ).then((res)=> {
        console.log('getTerminalPrinter:', res);
        if (res.data?.printerId) {
          // this.dialog.printer.printerSn = res.data.sn;
          this.dialog.printer.printerId = res.data.printerId;
          this.dialog.printer.isPrinterBound = true;
        }
      }).finally(() => {
        this.dialog.printer.isProcessing = false;
      });
    },
    bindTerminalPrinter() {
      const shopId = this.dialog.printer.shopId;
      const terminalId = this.dialog.printer.terminalId;
      // const printerSn = this.dialog.printer.printerSn ? this.dialog.printer.printerSn.trim() : '';
      const printerId = this.dialog.printer.printerId ? this.dialog.printer.printerId.trim() : '';

      this.dialog.printer.isProcessing = true;
      this.$validate([
        /* 'dialog.printer.printerSn', */
        'dialog.printer.printerId'
      ])
      .then((valid) => {
        if (!valid) {
          this.dialog.printer.isProcessing = false;
          return; 
        }
        axios.put(
          `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/ddpay/shops/${shopId}/terminals/${terminalId}/printer`,
          { /* sn: printerSn,  */printerId: printerId }
        ).then(()=> {
          this.$notify({
            type: 'success', 
            text: `關聯櫃位 ${terminalId} 出單機成功，出單機Device Id： ${printerId}。`
          });
          this.dialog.printer.isPrinterBound = true;
        }).catch(() => {
          this.$notify({
            type: 'error', title: `無法關聯出單機，請稍後再試。`
          });
        }).finally(() => {
          this.dialog.printer.isProcessing = false;
        });
      });
      
    },
    removeTerminalPrinter() {
      const shopId = this.dialog.printer.shopId;
      const terminalId = this.dialog.printer.terminalId;
      this.dialog.printer.isProcessing = true;
      axios.delete(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/ddpay/shops/${shopId}/terminals/${terminalId}/printer`,
      ).then(()=> {
        this.$notify({
          type: 'success', 
          text: `解除關聯櫃位 ${terminalId} 出單機成功。`
        });
        // this.dialog.printer.printerSn = '';
        this.dialog.printer.printerId = '';
        this.dialog.printer.isPrinterBound = false;
        this.validation.reset();
      }).catch(() => {
        this.$notify({
          type: 'error', title: `無法解除關聯出單機，請稍後再試。`
        });
      }).finally(() => {
        this.dialog.printer.isProcessing = false;
      });
    },
    printerTest () {
      const shopId = this.dialog.printer.shopId;
      const terminalId = this.dialog.printer.terminalId;
      const template = `<BOLD><C>出單測試</C></BOLD><C>櫃位：${terminalId}</C>`;
      this.dialog.printer.isPrinting = true;
      let data = {
        // shopId, 
        // terminalId,
        content: template
      }
      axios.post(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/ddpay/shops/${shopId}/terminals/${terminalId}/printer-test`,
        data
      ).catch(() => {
        this.$notify({
          type: 'error', title: `出單測試發生錯誤，請檢查出單機狀態後重試。`
        });
      }).finally(() => {
        this.dialog.printer.isPrinting = false;
      });
    }
  },

  data() {
    return {
      counters: [ ],

      form: {
        name: '',
        notifyUrl: '',
        isProcessing: false
      },

      dialog: {
        invite: {
          show: false,
          role: 'manager',
          url: '',
          roleOptions: [
            { value: 'manager', text: '管理員／店長' },
            { value: 'employee', text: '店員' }
          ]
        },
        printer: {
          show: false,
          isProcessing: false,
          shopId: '',
          terminalId: '',
          // printerSn: '',
          printerId: '',
          isPrinterBound: false,
          isPrinting: false
        }
      }
    }
  },

  computed: {
    user() {
      return this.$store.getters.user;
    }
  },

  watch: {
    'merchantId': {
      immediate: true,
      handler(mId) {
        this.counters = [ ];
        this.fetchCounters();
      }
    }
  },

  validators: {
    'form.name': v => {
      return Validator.value(v).required().minLength(4);
    },
    'form.notifyUrl': v => {
      return Validator.value(v).regex(new RegExp('http(s?):\/\/.*', 'gm'));
    },
    'dialog.invite.role': v => {
      return Validator.value(v).required();
    },
    // 'dialog.printer.printerSn': v => {
    //   return Validator.value(v).required().regex(new RegExp('^\\d{9}$'));
    // },
    'dialog.printer.printerId': v => {
      return Validator.value(v).required();
    }
  }
}
</script>
